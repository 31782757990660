<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송한 레몬레터 {{ viewModel.isModify ? '수정' : '등록' }}</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <!-- <tr>
          <th>발송일<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <DatePicker
              :value.sync="viewModel.model.date"/>
          </td>
        </tr> -->
        <tr>
          <th>제목<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="레몬레터 메일 제목을 입력해주세요"
              :value.sync="viewModel.model.title"/>
          </td>
        </tr>
        <tr>
          <th>내용<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Textarea
              placeholder="레몬레터 메일 내용 코드를 입력해주세요"
              :max="1000000"
              :isCount="true"
              :showLine="10"
              :value.sync="viewModel.model.contents"/>
          </td>
        </tr>
        <tr v-if="viewModel.model.contents">
          <th>수신거부 영역 제거</th>
          <td>
            <Button
              btnSize="small"
              btnStyle="secondary_border"
              text="수신거부 영역 제거"
              :disabled="!isHasUnSubscribe"
              @onClickBtn="onClickReplaceUnsubscribe()"/>
          </td>
        </tr>
        <tr v-if="viewModel.model.contents">
          <th>미리보기</th>
          <td>
            <div class="letter_preview">
              <div class="inner_preview">
                <div class="layer_head">
                  <h3 class="tit_layer">{{ viewModel.model.title }}</h3>
                </div>
                <div class="layer_body">
                  <div v-html="viewModel.model.contents"></div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          routeName="LANDING_LETTER_LIST"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          :disabled="isVaild"
          @onClickBtn="viewModel.onClickRegisterCompelete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import DatePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DatePicker';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import LetterDetailViewModel from '@/views/landing/letter/viewModel/LetterDetailViewModel'

export default {
  name:'LetterRegister',
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    DatePicker,
    Input,
    Textarea,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);
  },
  computed:{
    isHasUnSubscribe(){
      if(!this.viewModel.model.contents) return false;
      return this.viewModel.model.contents.indexOf('수신거부') > -1;
    },
    isVaild(){
      return this.viewModel.registerValidatedResult()
    }
  },
  data(){
    return{
      viewModel: new LetterDetailViewModel(),
    }
  },
  methods:{
    onClickReplaceUnsubscribe(){
      const unsubscribeTextArray = this.viewModel.model.contents.split('수신거부');
      const startDivArray = unsubscribeTextArray[0].split('<div');
      const startDiv = startDivArray[startDivArray.length-1];
      const endDivArray = unsubscribeTextArray[1].split('</div>');
      const endDiv = endDivArray[0];
      const unsubscribeCode = `<div${startDiv}수신거부${endDiv}</div>`;
      this.viewModel.model.contents = this.viewModel.model.contents.replace(unsubscribeCode,'');
    }
  }
}
</script>
<style scoped>
.letter_preview{padding:15px;background-color:rgba(0,0,0,.8)}
.letter_preview .inner_preview{display:inline-flex;flex-direction:column;position:relative;width:100%;max-height:calc(100vh - 100px);min-height:100px;padding:40px 0 60px;border-radius:18px;background-color:#fff;white-space:normal;vertical-align:middle;box-sizing:border-box;text-align:center}

.letter_preview .layer_head{padding:0 66px 20px}
.letter_preview .layer_head .tit_layer{font-weight:700;font-size:24px;line-height:46px;color:#333}

.letter_preview .layer_body{overflow-y:auto;max-height:500px;padding:0 66px;font-weight:600;text-align:left}
</style>